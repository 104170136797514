.container-div {
    font-family: "Times New Roman", Times, serif;
    margin: 1rem;
}

.center {
    text-align: center;
}

.underline {
    text-decoration: underline;
}

.align-left {
    text-align: left;
}

.blue {
    color: blue;
}